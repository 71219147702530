<template>
    <div class="wrap">
        <el-page-header class="top" @back="goBack" content="首页"></el-page-header>
        <img src="@/assets/table.png" width="100%" alt />
    </div>
</template>
<script>
export default {
    methods: {
        goBack() {
            this.$router.push({ path: '/' })
        }
    }
}
</script>
<style lang="scss" scoped>
.wrap {
    max-width: 1024px;
    margin: 0 auto;
    .top {
        height: 100px;
        display: flex;
        align-items: center;
    }
}
</style>